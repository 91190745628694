import React from 'react'
import {Typography} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import data from './data'
import ImageWithText from '../common'

export default function Benefits() {
    return (
        <Grid container xs={12} spacing={4}>
            <Grid item xs={12} style={{ marginBottom: '70px' }}>
                <Typography variant='h2'>
                    {'Benefits'}
                </Typography>
            </Grid>
            {
                data.map(val => (
                    <Grid item xs={12} md={4}>
                        <ImageWithText title={val.title} text={val.text} image={val.image} imageWidth={100}/>
                    </Grid>
                ))
            }
        </Grid>
    )
}
