import React from 'react'
import { makeStyles, Typography, Grid, IconButton, Button, Hidden } from '@material-ui/core'
import twitterLogo from './assets/twitter-logo.svg'
import mediumLogo from './assets/medium-logo.svg'
import linkedinLogo from './assets/linkedin-logo.svg'
import LaunchIcon from '@material-ui/icons/Launch'

const useStyles = makeStyles({
    footer: {
        backgroundColor: '#4E1E78',
        display: 'grid',
        gridTemplateRows: 'auto auto auto',
        gridTemplateColumns: 'repeat(12,1fr)',
        minHeight: '10rem',
        marginTop: '10rem',
    },
    underline: {
        gridRow: 1,
        gridColumnStart: 1,
        gridColumnEnd: 13,
        height: '1px',
        backgroundColor: '#6555C1'
    },
    contentWrapper: {
        gridRow: 2,
        gridColumnStart: 1,
        gridColumnEnd: 13,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: '20px'
    },
    text: {
        fontFamily: 'Glence',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '17px',
        textDecoration: 'none',
        color: '#CCCCCC'
    },
    trademarkWrapper: {
        gridRow: 3,
        gridColumnStart: 1,
        gridColumnEnd: 13,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    boldText: {
        textDecoration: 'none',
        fontFamily: 'Glence',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '24px'
    }
})

export default function MobileFooter() {
    const classes = useStyles()

    return (
        <div className={classes.footer}>
            <div className={classes.contentWrapper}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <a href='https://twitter.com/AmplifiedTM' target='_blank' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <img src={twitterLogo} style={{ marginRight: '2rem', cursor: 'pointer', width: 'fit-content' }} />
                        </a>
                        <a href='https://www.linkedin.com/company/amplifiedsoftware/' target='_blank' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <img src={linkedinLogo} style={{ marginRight: '2rem', cursor: 'pointer', width: 'fit-content' }} />
                        </a>
                        <a href='https://medium.com/amplified-software/about-amplified-7596c0585330' target='_blank' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <img src={mediumLogo} style={{ cursor: 'pointer', width: 'fit-content' }} />
                        </a>
                    </div>

                    <Button href='https://amplified.software'>
                        <Typography variant='h2' style={{ fontWeight: '800', fontSize: '28px' }}>Amplified</Typography>
                        <LaunchIcon fontSize='large' style={{ color: '#FA6754' }} />
                    </Button>
                </div>
            </div>
            <div className={classes.trademarkWrapper}>
                <h6 className={classes.text}>
                    All rights reserved 2021 - Amplified Software
				</h6>
            </div>
        </div>)
}