import React from 'react'
import { Grid, Typography } from '@material-ui/core'

export default function AplicationProcess () {
  return (
    <Grid container xs={12} style={{marginTop:'110px'}}>
        <Grid item xs={1}/>
        <Grid item xs={10}>
            <Typography variant='h2'>
                {'Application Process'}
            </Typography>
            <br/>
            <Typography variant='body1'>
                <a href='https://ian449310.typeform.com/to/fbfOya6F' target='_blank' style={{color:'#FA6754',textDecoration:'none', cursor:'pointer'}}>{'Complete this form by '}</a> <span style={{color:'#4E1E78', fontWeight:'800'}}>{'February 19.'}</span> {'Interviews will be extended on a first-come, first-serve basis, so don’t delay!'}
            </Typography>
            <br/>
            <Typography variant='body1'>
                {'Also, be sure to join our 30-minutes informational session on Zoom (details to come). You will get a chance to meet some of the members of the Amplified team, learn more about the Amplified Academy, and ask any questions. '}
            </Typography>
            <br/>
            <Typography variant='body1'>
                <span style={{color:'#6555C1'}}>{'Want to chat?'}</span> {'Please email'} <a href="mailto:someone@yoursite.com" target="_top" style={{color:'#FA6754', cursor:'pointer', textDecoration:'none'}}>{'academy@amplified.software'}</a>
            </Typography>
        </Grid>
        <Grid item xs={1}/>
    </Grid>)
}
