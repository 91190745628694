import { Typography } from '@material-ui/core'
import React from 'react'

export default function ImageWithText({ image, title, text, imageWidth, timelineVersion }) {
    if (timelineVersion) {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={image} style={{ maxWidth: `${imageWidth}px` }} />
                </div>
                <br />
                <Typography variant='body2' style={{ color: '#6555C1', textAlign: 'center' }}>
                    {title}
                </Typography>
                <br />
                <Typography variant='body2' style={{ textAlign: 'center', }}>
                    {text}
                </Typography>
            </div>)
    }
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', justifyContent: 'center', height:`${imageWidth}px` }}>
                <img src={image} style={{ width: `${imageWidth}px`,  }} />
            </div>
            <br />
            <Typography variant='h3'>
                {title}
            </Typography>
            <br />
            <Typography variant='body1'>
                {text}
            </Typography>
        </div>)
}