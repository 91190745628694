import { createMuiTheme } from '@material-ui/core'

const theme = createMuiTheme()

theme.typography.h1 = {
  fontFamily: 'HK Nova',
  fontStyle: 'normal',
  fontWeight: '900',
  color: '#FABA54',
  [theme.breakpoints.up('xs')]:{
    fontSize: '24px',
    lineHeight: '30px',
    textAlign:'center',
  },
  [theme.breakpoints.up('md')]:{
    fontSize: '54px',
    lineHeight: '68px',
    textAlign:'left'
  },

}

theme.typography.h2 = {

  fontFamily: 'HK Nova',
  fontStyle: 'normal',
  fontWeight: '900',
  color: '#FA6754',

  [theme.breakpoints.up('xs')]:{
    fontSize: '18px',
    lineHeight: '22.5px', 
  },
  [theme.breakpoints.up('md')]:{
    fontSize: '36px',
    lineHeight: '45px',  
  }

}

theme.typography.h3 = {

  fontFamily: 'HK Nova',
  fontStyle: 'normal',
  fontWeight: '800',
  color: '#FA6754',
  [theme.breakpoints.up('xs')]:{
    fontSize: '17px',
    lineHeight: '20.4px',  
  },
  [theme.breakpoints.up('md')]:{
    fontSize: '28px',
    lineHeight: '33.6px',
  },
}

theme.typography.body1 = {

  fontFamily: 'HK Nova',
  fontStyle: 'normal',
  fontWeight: '500',
  color: '#312F2F',
  [theme.breakpoints.up('xs')]:{
    fontSize: '15.36px',
    lineHeight: '18.43px',  
  },
  [theme.breakpoints.up('md')]:{
    fontSize: '24px',
    lineHeight: '28.8px',  
  }
}

theme.typography.body2 = {

  fontFamily: 'HK Nova',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '15.36px',
  lineHeight: '18.43px',
  color: '#312F2F',
}

theme.typography.button = {
  fontFamily: 'HK Nova',
  fontStyle: 'normal',
  fontWeight: '500',
  color: '#FA6754',
  [theme.breakpoints.up('xs')]:{
    fontSize: '15px',
    lineHeight: '18px',  
  },
  [theme.breakpoints.up('md')]:{
    fontSize: '30px',
    lineHeight: '36px',  
  },
}

export default theme
