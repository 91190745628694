import { Grid, Hidden, Typography } from '@material-ui/core'
import React from 'react'
import timelineImg from './assets/timeline.svg'
import data from './data'
import ImageWithText from '../common'

export default function Timeline() {
    return (
        <Grid container xs={12} style={{ marginTop: '110px' }}>
            <Hidden mdDown>
                <Grid item xs={1} />

                <Grid item xs={10}>
                    <img src={timelineImg} />
                </Grid>
                <Grid item xs={1} />
            </Hidden>
            <Hidden mdUp>
                <Typography variant='h2'>
                    {'Timeline'}
                </Typography>
                {data.map(val => (
                    <Grid container xs={12}>
                        <Grid item xs={12} style={{height:'60px'}}/>
                        <Grid xs={12}>
                            <ImageWithText image={val.image} title={val.title} text={val.text} imageWidth={60} timelineVersion />
                        </Grid>
                    </Grid>))}
            </Hidden>
        </Grid>)
}