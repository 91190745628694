import lightIcon from './assets/light-icon.png'
import track1Icon from './assets/track1-icon.png'
import track2Icon from './assets/track2-icon.png'


export default [
    {
        image:lightIcon,
        title:'Track 1: Innovation Exploration',
        text:'In the first nine weeks, you will learn the basics of design thinking, technology entrepreneurship, and innovation strategy. All Academy participants will be grouped in a single team. Together, they will be tasked to explore a market problem, discover stakeholder needs, design a lightweight solution (an MVP or a “minimally viable product”) and then build and launch a functioning application. ',
    },
    {
        image:track1Icon,
        title:'Track 2: Front-end or back-end',
        text:'At Amplified, every engineer is a full-stack developer. In track 2, you will spend six weeks building a new feature. You will either focus on frontend development in React or Angular, or backend development in Java or Node.JS. ',
    },
    {
        image:track2Icon,
        title:'Track 3: Back-end or front-end',
        text:'In track 3, you will, once again, spend six weeks building a new feature. However, you will rotate to the opposite side of the stack from what you worked on in track 2. So, if last month you were a frontend developer, this month you’ll be a backend developer.',
    },
]