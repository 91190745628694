import React from 'react'
import logoSrc from './assets/amplified-academy-logo.png'
import topSectionBg from './assets/top-section-bg.png'
import topGradient from './assets/top-gradient.png'
import topGradientMobile from './assets/top-gradient-mobile.png'
import topSectionBgMobile from './assets/top-section-bg-mobile.png'
import { Typography, Button, Container, Grid, Hidden } from '@material-ui/core'
import theme from '../themes'

export default function Header() {
  return (
    <div style={{ display: 'grid' }}>
      <Container style={{ gridRow: 1, gridColumn: 1, zIndex: 15 }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Hidden smDown>
            <img style={{ height: '100px', width: '175px' }} src={logoSrc} />
          </Hidden>
          <Hidden mdUp>
            <img style={{ height: '75px', width: '140px' }} src={logoSrc} />
          </Hidden>
          <Hidden smDown>
            <div style={{ marginTop: '50px' }} />
          </Hidden>
          <div style={{ marginTop: '20px' }} />
          <Grid item xs={12} md={9}>
            <Typography variant='h1'>
              {'Launching careers for young software developers!'}
            </Typography>
          </Grid>
          <Hidden smDown>
            <div style={{ marginTop: '10px' }} />
          </Hidden>
          <div style={{ marginTop: '40px' }} />
          <div>
            <Hidden mdUp>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <a target='_blank' href='https://ian449310.typeform.com/to/fbfOya6F'>               
                  <Button style={{ borderStyle: 'solid', maxWidth: '300px', borderColor: '#FA6754', borderWidth: '1px', paddingLeft: '30px', paddingRight: '30px' }}>
                  <Typography variant='button'>
                    {'Apply Now'}
                  </Typography>
                </Button>
                </a>

              </div>
            </Hidden>

            <Hidden smDown>
            <a target='_blank' href='https://ian449310.typeform.com/to/fbfOya6F'>               

              <Button Button style={{ borderStyle: 'solid', maxWidth: '300px', borderColor: '#FA6754', borderWidth: '1px', paddingLeft: '30px', paddingRight: '30px' }}>
                <Typography variant='button'>
                  {'Apply Now'}
                </Typography>
              </Button>
              </a>
            </Hidden>
          </div>
          <Hidden smDown>
            <div style={{ marginTop: '16px' }} />
          </Hidden>
          <div style={{ marginTop: '54px' }} />
        </div>
      </Container>

      <Hidden smDown>
        <img src={topGradient} style={{ gridRow: 1, gridColumn: 1, zIndex: 10, opacity: '1', width: '100%', height: '100%' }} />
        <img src={topSectionBg} style={{ gridRow: 1, gridColumn: 1, zIndex: 5, width: '100%', height: '100%' }} />
      </Hidden>
      <Hidden mdUp>
        <img src={topGradientMobile} style={{ gridRow: 1, gridColumn: 1, zIndex: 10, opacity: '1', width: '100%', height: '100%' }} />
        <img src={topSectionBgMobile} style={{ gridRow: 1, gridColumn: 1, zIndex: 5, width: '100%', height: '100%' }} />
      </Hidden>
    </div>)
}
