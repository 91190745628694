const content = [{
    "title": "What is Amplified Software?",
    "text": "Amplified is a startup studio. We are badass startup builders that partner with external startup teams to provide professional software and design services. With Amplified by its side, a startup can build quality products and grow its business fast and reliably. Additionally, we internally incubate new internet businesses, so we’re always building something new and sharing it with the world."
},
{
    "title": "Is the Academy program a limited training/internship program?",
    "text": "Yes and no. Once you are accepted into the Academy, you’ll join our team as a fully integrated member, without any limitation. This means that you’ll be working with us, under a legal unlimited work contract, and be paid accordingly. However, the rotational program of the Academy will end around the middle of December, when you’ll be graduating from the Academy, and become a regular Amplifier. You can look at the Academy as a way to build your work experience that many companies ask for before hiring directly on the job, through a fast-paced program."
},
{
    "title": "Why does it start in July?",
    "text": "We know that it’s important for you to be able to focus on your final exams, without having to worry about whether you’ll get a job afterwards. We value your time and education and want you to kickstart your career without worrying whether you’ll be able or not to graduate. We want you to have the best experience possible while working so we give you the necessary time to finish your studies."
},
{
    "title": "What is a Rotational Program?",
    "text": "Rotational Programs are a great way for newly graduates to try out many areas in their field, to see which fits them best, and a common practice at companies in the USA. As a participant in the software engineering RP, you’ll have the chance to quickly work, in specified intervals of time, on software designing, frontend and backend programming, giving you a taste of what each of these jobs do. "
},
{
    "title": "What else will I be doing during the Academy?",
    "text": `This can also depend on your own interests and strengths. The main course for all Academy participants consists of 3 stages:\n1. software design and planning;\n2. Frontend or backend implementation \n3. Switching to building the opposite side of what you implemented in part 2. 
    However, if you have interest in other advanced tech (or are a Masters student) let’s talk to see if we can create a different track, better suited to your experience.
    Lastly, there’s always the chance that we’ll notice great potential in you, and you might fast-forward through the program, having the chance to join one of our project teams sooner. 
    And most of all, you will be working in a fun, young and energetic team, improving the soft skills you’ll need in your career and make new friends.`
},
{ "title": "What happens once the Academy ends?", "text": "You’ll remain in our team and you’ll receive a certificate, recognizing all your achievements during the program, thus becoming an Amplifier. However, this can also happen earlier, if you show great potential and you want, we’ll fast-forward you through the program so you can “graduate earlier”." },
{ "title": "Can I add this experience to my CV?", "text": "Of course. While part of the Amplified team, you’ll have the chance to work on real projects, interact with clients, and work alongside other developers. You’ll be a full time Amplified Software employee, so it’s as real as it gets!" },
{ "title": "Will I get paid while in the Academy?", "text": "Yes, as an Amplified employee, you’ll enjoy a monthly salary and benefits, aligned with our compensation structure. More details you will find in the offer we extend to you after the interviews." }
]

export default content