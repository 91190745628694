import choiceIcon from './assets/choice-icon.png'
import consoleIcon from './assets/console-icon.png'
import degreeIcon from './assets/degree-icon.png'
import mailIcon from './assets/mail-icon.png'
import questionIcon from './assets/questions-icon.png'
import workingIcon from './assets/working-icon.png'

export default [
    {
        image:choiceIcon,
        title:'January 15',
        text:'Applications open',
    },
    {
        image:questionIcon,
        title:'February 1',
        text:'Behavioral interviews',
    },
    {
        image:questionIcon,
        title:'February 15',
        text:'Technical interviews',
    },
    {
        image:mailIcon,
        title:'February 26',
        text:'Round 1 Job Offers Extended',
    },
    {
        image:choiceIcon,
        title:'March 12',
        text:'Round 2 Job Offers Extended',
    },
    {
        image:consoleIcon,
        title:'March - July',
        text:'For admitted Academy participants, ongoing engagement and socialization through virtual programming',
    },
    {
        image:workingIcon,
        title:'July 26',
        text:'First day of work, beginning of Track 1',
    },
    {
        image:workingIcon,
        title:'September 27',
        text:'Beginning of Track 2',
    },
    {
        image:workingIcon,
        title:'November 15',
        text:'Beginning of Track 3',
    },
    {
        image:degreeIcon,
        title:'December 17',
        text:'Academy graduation, start of winter break',
    },

]