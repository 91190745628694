import earlyHiringIcon from './assets/early-hiring-icon.png'
import employmentIcon from './assets/employment-icon.png'
import mentorshipIcon from './assets/mentorship-icon.png'
import trainingIcon from './assets/training-icon.png'
import certificateIcon from './assets/certificate-icon.png'
import experienceIcon from './assets/experience-icon.png'

export default [
    {
        image:earlyHiringIcon,
        title:'Early Hiring',
        text:'With applications opening in January, and job offers extended in February and March, you will be able to finish your academic year with a resolved employment status and peace of mind,',
    },
    {
        image:employmentIcon,
        title:'Employment',
        text:'Academy participants will be fully employed by Amplified, receiving standard wages for early-career software developers as well as standard benefit packages. ',
    },
    {
        image:mentorshipIcon,
        title:'Mentorship',
        text:'To ensure that each technical assignment is successful, Academy participants will be paired with senior software developers who will serve as mentors throughout the program. ',
    },
    {
        image:trainingIcon,
        title:'Training',
        text:'Throughout the program, Academy participants will be offered dedicated time and resources for studying new concepts, so you can become full-stack developers and innovation catalysts. ',
    },
    {
        image:experienceIcon,
        title:'Experience',
        text:'Gain authentic work experience by pushing software to production, for real users, all while working alongside a team that is eager to support you.',
    },
    {
        image:certificateIcon,
        title:'Certification',
        text:'At the completion of the program, graduates will receive a certification that recognises their achievements and their acquisition of new technical and soft skills.',
    },

]