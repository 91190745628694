import React from 'react'
import { Hidden, Typography, Grid } from '@material-ui/core'
import data from './data'
import ImageWithText from '../common'

export default function Tracks() {
  return (
    <div style={{ marginTop: '80px', display: 'flex', flexDirection: 'column' }}>
      {
        data.map(val => (
          <Grid container xs={12}>
            <Hidden mdDown>
              <Grid xs={2} />
            </Hidden>
            <Grid item xs={12} md={8}>
              <ImageWithText image={val.image} title={val.title} text={val.text} imageWidth={150} />
            </Grid>
            <Hidden mdDown>
              <Grid xs={2} />
            </Hidden>
            <Hidden mdDown>
              <Grid item xs={12} style={{ height: '80px' }} />
            </Hidden>
            <Hidden mdUp>
              <Grid item xs={12} style={{ height: '30px' }} />
            </Hidden>
          </Grid>
        ))
      }
      <Grid container xs={12}>
        <Hidden mdDown>
          <Grid xs={2} />
        </Hidden>
        <Grid item xs={12} md={8}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant='h3'>
              {`Are you enrolled in a Master's Program?`}
            </Typography>
            <br />
            <Typography variant='body1'>
              <span color='#4F1F78'>{`Drop us a line `}</span> {`and we'll work together to find a track that's better suited to your expertise!`}
            </Typography>
          </div>
        </Grid>
        <Hidden mdDown>
          <Grid xs={2} />
        </Hidden>
        <Hidden mdDown>
          <Grid item xs={12} style={{ height: '80px' }} />
        </Hidden>
        <Hidden mdUp>
          <Grid item xs={12} style={{ height: '30px' }} />
        </Hidden>
      </Grid>
    </div>)
}
