import React from 'react'
import { Container, Hidden } from '@material-ui/core'
import Header from './header'
import Landing from './landing'
import Tracks from './tracks'
import Benefits from './benefits'
import ApplicationProcess from './aplicationProcess'
import Timeline from './timeline'
import Footer from './common/Footer'
import MobileFooter from './common/MobileFooter'
import FAQSection from './faq'

function App() {
  return (
    <div div>
      <Header />
      <Container>
        <Landing />
        <Tracks />
        <Benefits />
        <Timeline />
        <ApplicationProcess />
        <FAQSection/>
      </Container>

      <Hidden only={['xs','sm']}>
        <Footer />
      </Hidden>
      <Hidden only={['md','xl','lg']}>
        <MobileFooter />
      </Hidden>
    </div >)
}

export default App
