import React from 'react'
import { Typography, Grid, Hidden } from '@material-ui/core'

import rocketImg from './assets/rocket.svg'
import aboutUsImg from './assets/about-us.svg'

export default function Landing() {
  return (
    <div>
      <Grid container xs={12} style={{ marginTop: '80px' }}>
        <Hidden mdDown>
          <Grid item xs={1} />
        </Hidden>
        <Grid item xs={12} md={3}>
          <img src={rocketImg} />
        </Grid>
        <Hidden mdDown>
          <Grid item xs={1} />
        </Hidden>
        <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center  ' }}>
          <Typography variant='body1'>
            {'The Amplified Academy is a rotational development program for recent college graduates. By rotating through different technical assignments, Academy participants gain experience in full-stack software development and technology entrepreneurship. At the conclusion of the 21-week program, Academy participants receive a certification and “graduate” into Amplified’s standard employment track.  '}
          </Typography>
        </Grid>
      </Grid>

      <Grid container xs={12} style={{ marginTop: '110px' }}>
        <Grid container xs={12}>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <Typography variant='h2'>
              {'About Us'}
            </Typography>
            <br />

          </Grid>
          <Grid item xs={1} />
        </Grid>

        <Grid container xs={12}>
          <Hidden mdDown>
            <Grid item xs={1} />
          </Hidden>
          <Grid item xs={12} md={6}>
            <Typography variant='body1'>
              <a href='https://amplified.software' target='_blank' style={{textDecoration:'none', color:'#4F1F78'}}>{'The Amplified Academy is powered by Amplified Software, a startup studio operating in Iasi.'}</a>{'Amplified is an American company that provides professional software and design services to technology startups, most of which are based in Silicon Valley. Additionally, Amplified incubates and launches its own software and internet businesses. Amplified is a great place to work for anyone who is constantly striving to learn new things and enjoys building new software products in a fun and fast, entrepreneurial environment. '}
            </Typography>
          </Grid>
          <Hidden mdDown>
            <Grid item xs={1} />
          </Hidden>

          <Grid item xs={12} md={3} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center  ' }}>
            <img src={aboutUsImg} />
          </Grid>
          <Hidden mdDown>
            <Grid item xs={1} />
          </Hidden>
        </Grid>
        <Grid container xs={12}>

          <Hidden>
            <Grid item xs={1} />
          </Hidden>
          <Grid item xs={12} md={10} style={{ marginTop: '80px' }}>
            <Typography variant='h2'>
              {'Amplified Academy'}
            </Typography>
            <br />
            <Typography variant='body1'>
              {'The Amplified Academy is a unique program designed specifically for recent college graduates in informatics or computer science. To train the next generation of Amplifiers, the Academy offers an extended onboarding experience that lasts 21-weeks and is divided into three separate tracks.'}
            </Typography>
          </Grid>
          <Hidden>
            <Grid item xs={1} />
          </Hidden>
        </Grid>

      </Grid>
    </div >)
}
